import HeroBlock from "../../components/HeroBlock/HeroBlock";
import CardBlocks from "../../components/CardBlocks/CardBlocks";
import FeatureBlock from "../../components/FeatureBlock/FeatureBlock";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import { AboutContent } from "./About-Content";
import TwoColumn from "../../components/TwoColumn/TwoColumn";

const About: React.FC = () => {
  return (
    <>
        <HeroBlock
            textAlign="Center"
            style={{minHeight: '30rem'}}
            containerStyle={{maxWidth: '50rem'}}
            content={AboutContent.heroBreadCrumb}
        />

        <SectionBanner
            textAlign="Center"
            linkClass="bg-color-alice-blue pt-5 pb-3"
            content={AboutContent.sectionBanner1}
        />

        <section className="bg-color-alice-blue">
            <div className="container pb-5">
                <CardBlocks
                    gap={'1.75rem'}
                    maxCardsPerRow={3}
                    itemClass="text-center"
                    content={AboutContent.cardBlocks1}
                />
            </div>
        </section>

        <section className="pt-5 mt-3">
            <FeatureBlock
                imagePosition="left"
                content={AboutContent.featureBlock1}
            />
        </section>

        <section className="pt-5 mt-3">
            <FeatureBlock
                imagePosition="right"
                content={AboutContent.featureBlock2}
            />
        </section>

        <section className="pt-5 mt-3">
            <div className="bg-color-alice-blue">
                <TwoColumn
                    leftColumnPercentage={40}
                    content={AboutContent.customBlock1}
                />
            </div>
        </section>
    </>
  );
}

export default About;
