import { Link } from "react-router-dom";
import { getHTML } from "../../services/helper";
import './CardBlocks.scss';

interface IProps {
  className?: string;
  titleClass?: string;
  subTitleClass?: string;
  itemClass?: string;
  gap?: string;
  maxCardsPerRow: number;
  content: {
    header?: string;
    description?: string;
    items?: {
      iconClass?: string;
      header?: string;
      description?: string;
      button?: {
        url: string;
        name: string;
      }
    }[];
  };
}

const CardBlocks: React.FC<IProps> = (props: IProps) => {
  return (
    <div id="content-block" className={props.className}>
      {props.content.header && (
        <h2 className={props.titleClass || 'font-weight-extra-light border-bottom-1px pb-2 mb-4'}>{props.content.header}</h2>
      )}
      {props.content.description && (
        <p className={props.subTitleClass || 'font-weight-extra-light pb-2 mb-4'}>{props.content.description}</p>
      )}
      <div className={`card-grid d-grid card-grid-${props.maxCardsPerRow > 4 ? '4' : props.maxCardsPerRow}`} style={{ gap: props.gap || '1rem' }}>
        {props.content.items &&
          props.content.items.length > 0 &&
          props.content.items.map((item, index) => (
            <div key={index} className={`card-item h-100 ${props.itemClass ?? ''}`}>
              <h5 className="h5 my-2">
                <i className={"mb-3 fa-2x " + item?.iconClass}></i>
                <div dangerouslySetInnerHTML={getHTML(item.header || "")}></div>
              </h5>
              <p
                className="text-muted"
                dangerouslySetInnerHTML={getHTML(item.description || "")}
              ></p>
              {item.button && (
                <Link
                  className="d-inline-block text-white my-2 link w-100"
                  to={item.button.url}
                >
                  <button
                    className="btn bg-color-primary shadow-md text-light w-100"
                    type="button"
                    style={{maxWidth: '200px'}}
                  >
                    {item.button.name}
                  </button>
                </Link>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CardBlocks;
