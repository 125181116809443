import React from 'react';
import Paragraph from '../Paragraph/Paragraph';

interface IProps {
  linkClass?: string;
  textAlign: 'Center' | 'Left' | 'Right';
  content: {
    header: string;
    subHeader?: string;
    description: string;
    button?: {
      url: string;
      name: string;
    }
  }
}

const ContentBlock: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={`container py-4 ${props.linkClass ?? ''}`}>
      { props.content.subHeader && (
        <div className="row">
          <div className="col-12 pb-3">
            <span className="title-span3">{props.content.subHeader}</span>
          </div>
        </div>
      )}
      <div className="row">
        <div className={`col-12 col-md-5 text-md-${props.textAlign.toLowerCase()} ${props.textAlign === 'Right' ? 'text-center' : ''}`}>
          <h2 className="font-weight-light">{props.content.header}</h2>
        </div>
        <div className="col-12 col-md-7">
          <Paragraph
            content={{
                description: props.content.description,
                button: props.content.button
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentBlock;