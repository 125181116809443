import { Link, useLocation } from "react-router-dom";
import useClickOutside from "../../services/click-outside";
import Logo from "../../assets/images/logos/logo.png";
import './Header.scss';

interface INavLink {
  to: string;
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any;
}

const NavLink = (props: INavLink) => {
  const location = useLocation();
  const isActive = location.pathname === props.to;

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    props.onClick && props.onClick(event);
  }

  return (
    <div className="d-block p-2">
      <Link
        className={`p-0 ${props.className || ''} ${isActive ? "active" : ""}`}
        to={props.to}
        onClick={handleOnClick}
      >
        {props.children}
      </Link>
    </div>
  );
};

const Header: React.FC = () => {
  const { isVisible, toggleIsVisible, ref } = useClickOutside(false);
  return (
    <header className="navbar navbar-expand-md fixed-top navbar-light bg-color-alice-blue-095 px-md-0" ref={ref}>
      <nav className="container">
        <Link
          className="text-dark py-2 px-md-1"
          to="/"
          onClick={() => (isVisible ? toggleIsVisible() : "")}
        >
          <img
            alt="Logo"
            src={Logo}
            className="img-fluid logo-image"
            style={{ maxWidth: "7.75rem" }}
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler border-color-primary"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          onClick={toggleIsVisible}
          aria-expanded={isVisible}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={
            "collapse navbar-collapse pl-2 pl-md-0" +
            (isVisible ? " in show" : "")
          }
          id="navbarCollapse"
          aria-expanded={isVisible}
        >
          <div className="navbar-nav ml-auto">
            <NavLink
              className="nav-item nav-link d-inline-block"
              to="/"
              onClick={toggleIsVisible}
            >
              Home
            </NavLink>
            <NavLink
              className="nav-item nav-link d-inline-block"
              to="/about-us"
              onClick={toggleIsVisible}
            >
              About
            </NavLink>
            <NavLink
              className="nav-item nav-link d-inline-block"
              to="/why-us"
              onClick={toggleIsVisible}
            >
              Why RAYITS?
            </NavLink>
            <NavLink
              className="nav-item nav-link d-inline-block"
              to="/services"
              onClick={toggleIsVisible}
            >
              Services
            </NavLink>
            <NavLink
              className="nav-item nav-link d-inline-block"
              to="/contact"
              onClick={toggleIsVisible}
            >
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
