import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IProps {
  fixedHeight?: number;
  showDots?: boolean;
  content: {
    images: string[];
  }
}

const CarouselReactive: React.FC<IProps> = (props: IProps) => {
  const sliderRef = React.useRef<Slider | null>(null);
  const settings = {
    className: "slider variable-width",
    dots: props.showDots,
    draggable: true,
    swipe: true,
    swipeToSlide: true,
    touchMove: true,
    accessibility: true,
    arrows: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {props.content.images.length && props.content.images.map((image, index) => (
        <img key={index} src={image} alt={`Img${index + 1}`} height={props.fixedHeight} />
      ))}
    </Slider>
  );
};

export default CarouselReactive;