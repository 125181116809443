import { Link } from "react-router-dom";
import BackToTop from "../BackToTop/BackToTop";
import Logo from "../../assets/images/logos/logo-footer.png";
import './Footer.scss';

const Footer: React.FC = () => {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    }
    return (
        <footer className="bg-gradient text-white">
            <BackToTop />
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <img
                            alt="Logo"
                            src={Logo}
                            className="img-fluid logo-image invert-image-color"
                            style={{ maxWidth: "10.5rem" }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className="list-inline border-links text-center mb-0 pt-3 pb-4 initialism">
                            <li className="list-inline-item d-block d-md-inline-block py-2">
                                <Link className="text-white py-2 px-md-1" to="/">Home</Link>
                            </li>
                            <li className="list-inline-item d-block d-md-inline-block py-2">
                                <Link className="text-white py-2 px-md-1" to="/about-us">About</Link>
                            </li>
                            <li className="list-inline-item d-block d-md-inline-block py-2">
                                <Link className="text-white py-2 px-md-1" to="/why-us">Why RAYITS?</Link>
                            </li>
                            <li className="list-inline-item d-block d-md-inline-block py-2">
                                <Link className="text-white py-2 px-md-1" to="/services">Services</Link>
                            </li>
                            <li className="list-inline-item d-block d-md-inline-block py-2">
                                <Link className="text-white py-2 px-md-1" to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-12 py-2 py-md-0 text-center small">
                       Copyright © { getCurrentYear() } RAYITS LLC. All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
