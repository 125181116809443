interface IButton {
    id: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    className?: string;
    label: string;
}

interface IButtonGroup {
    className?: string;
    gap?: string;
    onClick?: (id: string) => void;
    content: {
        list: IButton[];
    }
}

const ButtonGroup: React.FC<IButtonGroup> = (props: IButtonGroup) => {
    const handleClick = (id: string) => {
        props.onClick && props.onClick(id);
    }
    return (
        <div className={`d-flex flex-wrap justify-content-center ${props.className ?? ''}`} style={{ gap: props.gap || '1.5rem' }}>
            {
                props.content.list.map((item, index) => (
                    <button
                        key={index}
                        id={item.id}
                        type={item.type || 'button'}
                        className={item.className}
                        onClick={() => handleClick(item.id)}>
                        {item.label}
                    </button>
                ))
            }
        </div>
    );
};

export default ButtonGroup;
