import FeatureBlock from "../../components/FeatureBlock/FeatureBlock";
import Carousel from "../../components/Carousel/Carousel";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import CardBlocks from "../../components/CardBlocks/CardBlocks";
import CarouselReactiveBlock from "../../components/CarouselReactiveBlock/CarouselReactiveBlock";
import { HomeContent } from "./Home-Content";
import "./Home.scss";
import ContentBlock from "../../components/ContentBlock/ContentBlock";

const Home: React.FC = () => {
  return (
    <>
      <Carousel
        slideDelay={7}
        linkClass="carousel-custom-class"
        content={HomeContent.carousel}
      />

      <SectionBanner
        textAlign="Center"
        linkClass="bg-color-alice-blue py-4"
        content={HomeContent.sectionBanner1}
      />

      <section className="container pt-5 pb-4">
        <CardBlocks
          maxCardsPerRow={3}
          gap={'1.5rem'}
          titleClass="text-center"
          subTitleClass="text-center text-secondary mb-5"
          itemClass="text-center border border-dash px-4 py-5"
          content={HomeContent.cardBlocks1}
        />
      </section>

      <section className="pt-5">
        <FeatureBlock
          imagePosition="left"
          backgroundClass='bg-color-alice-blue'
          content={HomeContent.featureBlock1}
        />
      </section>

      <ContentBlock
        linkClass="mt-5"
        textAlign="Left"
        content={HomeContent.contentBlock1}
      />

      <section className="pt-5">
        <FeatureBlock
          imagePosition="right"
          backgroundClass='bg-color-alice-blue'
          content={HomeContent.featureBlock2}
        />
      </section>

      <section className="py-5">
        <CarouselReactiveBlock
          designType="full"
          fixedHeight={67}
          content={HomeContent.carouselTech}
        />
      </section>

      <SectionBanner
        textAlign="Center"
        linkClass="bg-color-alice-blue mt-5 py-4"
        content={HomeContent.sectionBanner2}
      />
    </>
  );
};

export default Home;
