import Image1 from "../../assets/images/services/hero.jpeg";
import FeatureImage1 from "../../assets/images/services/features/feature-1.jpeg";
import FeatureImage2 from "../../assets/images/services/features/feature-2.jpeg";
import FeatureImage3 from "../../assets/images/services/features/feature-3.jpeg";
import FeatureImage4 from "../../assets/images/services/features/feature-4.jpeg";
import FeatureImage5 from "../../assets/images/services/features/feature-5.jpeg";
import Paragraph from "../../components/Paragraph/Paragraph";

export const ServicesContent = {
  heroBreadCrumb: {
    image: Image1,
    header: "Our Services",
    description: `Discover a wide range of services offered by RAYITS. We're here to empower your business with technology solutions tailored to your needs.`,
  },
  sectionBanner1: {
    header: "Explore Our Offerings",
    description:
      "Dive into our comprehensive suite of services designed to enhance your organization's capabilities.",
  },
  paragraph1: {
    header: "Tailored Solutions",
    description:
      "At RAYITS, we understand that every business has unique requirements. We offer customized solutions that align with your specific needs that maintain latest industry standards. Whether you need expert IT consulting, comprehensive managed services, efficient project management, or custom software development, we have the expertise and experience to empower your business.",
  },
  buttonGroup: {
    list: [
      {
        id: "featureBlock1",
        className: "btn btn-outline-secondary",
        label: "IT Consulting Services",
      },
      {
        id: "featureBlock2",
        className: "btn btn-outline-secondary",
        label: "Project & Development",
      },
      {
        id: "featureBlock3",
        className: "btn btn-outline-secondary",
        label: "Infrastructure Services",
      },
      {
        id: "featureBlock4",
        className: "btn btn-outline-secondary",
        label: "Managed Services",
      },
      {
        id: "featureBlock5",
        className: "btn btn-outline-secondary",
        label: "Education & Training",
      },
    ],
  },
  featureBlock1Title: {
    header: "IT Services",
    description: "Strategic Insights",
  },
  featureBlock1: {
    image: FeatureImage1,
    block: [
      {
        component: Paragraph,
        props: {
          descriptionClassName: "text-secondary",
          content: {
            description:
              "Our expert team provides IT consulting services to develop strategic insights and solutions. We also offer comprehensive managed services for end-to-end IT management. Explore how we can enhance your IT capabilities.",
          },
        },
      },
      {
        component: Paragraph,
        props: {
          content: {
            description: `
              <p class="mb-1">Key Services Includes:</p>
              <ul class="pl-4 text-secondary">
                <li>Business Analysis</li>
                <li>Technology Roadmap</li>
                <li>Digital Transformation</li>
                <li>Network Management</li>
                <li>Cybersecurity Assessment</li>
                <li>24/7 Technical Support</li>
              </ul>
            `,
          },
        },
      },
    ],
  },
  featureBlock2Title: {
    header: "Project & Development",
    description: "Effective Project Delivery & Custom Solutions",
  },
  featureBlock2: {
    image: FeatureImage2,
    block: [
      {
        component: Paragraph,
        props: {
          descriptionClassName: "text-secondary",
          content: {
            description:
              "Our project management team ensures the effective delivery of your IT projects. We also offer custom software development services, from concept to deployment.",
          },
        },
      },
      {
        component: Paragraph,
        props: {
          content: {
            description: `
              <p class="mb-1">Key Services Include:</p>
              <ul class="pl-4 text-secondary">
                <li>Project Planning and Execution</li>
                <li>Resource Allocation and Management</li>
                <li>Quality Assurance and Testing</li>
                <li>Change Management</li>
                <li>Application Design and Prototyping</li>
                <li>Software Development and Coding</li>
                <li>Deployment and Post-launch Support</li>
              </ul>
            `,
          },
        },
      },
    ],
  },
  featureBlock3Title: {
    header: "Infrastructure Services",
    description: "Reliable Infrastructure Solutions",
  },
  featureBlock3: {
    image: FeatureImage3,
    block: [
      {
        component: Paragraph,
        props: {
          descriptionClassName: "text-secondary",
          content: {
            description:
              "Our infrastructure services ensure your IT systems operate efficiently. We offer cloud infrastructure management, server and network maintenance, data center solutions, and IT infrastructure auditing.",
          },
        },
      },
      {
        component: Paragraph,
        props: {
          content: {
            description: `
              <p class="mb-1">Key Services Include:</p>
              <ul class="pl-4 text-secondary">
                <li>Cloud Infrastructure Management</li>
                <li>Server and Network Maintenance</li>
                <li>Data Center Solutions</li>
                <li>IT Infrastructure Auditing</li>
              </ul>
            `,
          },
        },
      },
    ],
  },
  featureBlock4Title: {
    header: "Managed IT Services",
    description: "Efficient IT Management",
  },
  featureBlock4: {
    image: FeatureImage4,
    block: [
      {
        component: Paragraph,
        props: {
          descriptionClassName: "text-secondary",
          content: {
            description:
              "Our managed IT services provide efficient IT management for your business. We offer proactive monitoring, maintenance, and support to ensure your systems run smoothly.",
          },
        },
      },
      {
        component: Paragraph,
        props: {
          content: {
            description: `
              <p class="mb-1">Key Services Include:</p>
              <ul class="pl-4 text-secondary">
                <li>Proactive System Monitoring</li>
                <li>Regular Maintenance and Updates</li>
                <li>Data Backup and Recovery</li>
                <li>IT Security Services</li>
                <li>Vendor Management</li>
                <li>Comprehensive Reporting</li>
              </ul>
            `,
          },
        },
      },
    ],
  },
  featureBlock5Title: {
    header: "Education & Training",
    description: "Learning Opportunities",
  },
  featureBlock5: {
    image: FeatureImage5,
    block: [
      {
        component: Paragraph,
        props: {
          descriptionClassName: "text-secondary",
          content: {
            description:
              "We provide technology training and development opportunities, including technology training courses, workshops, seminars, personalized coaching, and certification preparation.",
          },
        },
      },
      {
        component: Paragraph,
        props: {
          content: {
            description: `
              <p class="mb-1">Key Services Include:</p>
              <ul class="pl-4 text-secondary">
                <li>Technology Training Courses</li>
                <li>Workshops and Seminars</li>
                <li>Personalized Coaching</li>
                <li>Certification Preparation</li>
              </ul>
            `,
          },
        },
      },
    ],
  },
  sectionBanner: {
    header: "Ready to Transform Your IT?",
    description: "Contact us today to discuss your IT needs and start your journey with RAYITS.",
    button: {
      url: "/contact",
      name: "Contact US",
    },
  },
};
