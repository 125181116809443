import { Link } from "react-router-dom";

interface IProps {
  linkClass?: string;
  textAlign: 'Center' | 'Left' | 'Right';
  content: {
    header?: string;
    description?: string;
    button?: {
      url: string;
      name: string;
    };
    captionText?: string;
  };
}

const SectionBanner: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={props.linkClass ? props.linkClass : "bg-light"}>
      <div className="container">
        <div className="row">
          <div className={`col-12 text-${props.textAlign.toLowerCase()} py-2`}>
            {props.content.header && <h2 className="pb-2">{props.content.header}</h2>}
            {props.content.description && <h6 className="font-weight-light pb-1">{props.content.description}</h6>}
            {props.content.button && (
              <Link
                className="d-inline-block text-white py-2 px-md-1"
                to={props.content.button.url}
              >
                <button
                  className="btn bg-color-secondary shadow-md btn-round text-light btn-md btn-rised w-175"
                  type="button"
                >
                  {props.content.button.name}
                </button>
              </Link>
            )}
            {props.content.captionText && (
              <h6 className="font-weight-light pt-2">{props.content.captionText}</h6>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBanner;
