import { renderComponentJson } from "../../services/render-component-json";
import './FeatureBlock.scss'

interface IProps {
    className?: string;
    designType?: 'full' | 'compact';
    backgroundClass?: string;
    imagePosition: 'left' | 'right';
    content: {
        image: string;
        block: any;
    }
}

const FeatureBlock: React.FC<IProps> = (props: IProps) => {
    return (
        <div className={`${props.designType === 'full' ? (props.backgroundClass || '') : ''} ${props.className ?? ''}`}>
            <div className={`container ${props.designType === 'full' ? 'py-5' : ''}`}>
                <div className={`${props.designType === 'full' ? '' : `px-3 rounded ${props.backgroundClass || ''}`}`}>
                    <div className={`row ${props.imagePosition === 'left' ? ' direction-rtl' : ''}`}>
                        <div className={`col-12 col-md-6 align-self-center direction-ltr py-5 ${props.designType === 'full' ? '' : 'px-5'}`}>
                            { renderComponentJson(props.content.block) }
                        </div>
                        <div className={`col-12 col-md-6 text-center pt-4 pt-md-0 image-container ${props.designType === 'full' ? 'my-auto' : 'd-flex align-items-stretch p-0'}`}>
                            <img className={'img-fluid rounded ' + (props.imagePosition === 'left' ? 'float-left' : 'float-right') } src={props.content.image} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureBlock;
