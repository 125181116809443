import { useEffect, useRef, useState } from "react";

const useClickOutside = (initialState: boolean) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const ref = useRef<HTMLDivElement | null>(null);

  const toggleIsVisible = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { isVisible, setIsVisible, toggleIsVisible, ref };
}

export default useClickOutside;
