import { getHTML } from "../../services/helper";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./HeroBlock.scss";

interface IProps {
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    showBreadCrumb?: boolean;
    textAlign: "Center" | "Left" | "Right";
    content: {
        image?: string;
        header?: string;
        description?: string;
    }
}

const HeroBlock: React.FC<IProps> = (props: IProps) => {
    return (
        <section id="page-title" className={ props.content.image ? 'has-background' : ''} style={{backgroundImage: `url(${props.content.image})`}}>
            <div className={`d-flex ${props.content.image ? 'back-drop' : 'bg-color-secondary'} text-${props.textAlign.toLowerCase()}`} style={props.style}>
                <div className="container clearfix text-white py-3 my-auto" style={props.containerStyle}>
                    <div className="row">
                        <div className="col-12 pt-5">
                            <h1>{props.content.header?.toUpperCase()}</h1>
                        </div>
                        <div className="col-12 py-4">
                            <h6 dangerouslySetInnerHTML={getHTML(props.content.description || '')}></h6>
                        </div>
                    </div>
                    {   
                        props.showBreadCrumb &&
                        <div className="row">
                            <div className="col-12">
                                <BreadCrumb textColor="#FFFFFF" removeBackground={true} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default HeroBlock;
