import React, { useState, useEffect } from "react";
import './BackToTop.scss'

const BackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((window.pageYOffset || window.screenY) > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button
      className={`btn btn-warning position-fixed rounded-circle back-to-top-button ${isVisible ? "d-block" : "d-none"}`}
      onClick={scrollToTop}
    >
      <em className="fa-solid fa-angles-up"></em>
    </button>
  );
};

export default BackToTop;
