import { useEffect, useState } from "react";

interface IImageClass {
    image: string;
    className: string;
}

interface IProps {
    linkClass?: string;
    default?: IImageClass;
    mobile?: IImageClass;
    tablet?: IImageClass;
    desktop?: IImageClass;
}

const ImageResponsive: React.FC<IProps> = (props: IProps) => {
    const [src, setSrc] = useState(props.default?.image || '');
    const [className, setClassName] = useState(props.default?.className || '');

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            let _src = src;
            let _className = className;

            if (props.default?.image) {
                _src = props.default.image;
                _className = props.default.className;
            } else if (windowWidth <= 575) {
                _src = props.mobile?.image || props.tablet?.image || props.desktop?.image || _src;
                _className = props.mobile?.className || '';
            } else if (windowWidth <= 767) {
                _src = props.tablet?.image || props.desktop?.image || _src;
                _className = props.tablet?.className || '';
            } else {
                _src = props.desktop?.image || _src;
                _className = props.desktop?.className || '';
            }

            setSrc(_src);
            setClassName(_className);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.default, props.mobile, props.tablet, props.desktop, src, className]);

    return (
        <>
            <img src={src} alt="responsiveimage" className={`${className} ${props.linkClass ?? ''}`} />
        </>
    );
};

export default ImageResponsive;
