import { Link, useLocation } from "react-router-dom";
import "./BreadCrumb.scss";

interface IProps {
  removeBackground?: boolean;
  textColor?: string;
}

const BreadCrumb: React.FC<IProps> = (props: IProps) => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path !== "");

  const capitalize = (str: string) => {
    const words = str.replace(/([A-Z])/g, ' $1').split(/[^a-zA-Z0-9]+/);
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  };

  return (
    <nav aria-label="breadcrumb" className="d-inline-block">
      <ol className={'breadcrumb' + (props.removeBackground ? ' px-0 mb-0' : '')} style={{backgroundColor: `${props.removeBackground ? 'inherit' : undefined}`}}>
        <li className="breadcrumb-item">
          <Link to="/" style={{color: props.textColor}}>Home</Link>
        </li>
        {paths.map((path, index) => (
          <li key={index} className={'breadcrumb-item' + (props.textColor ? ' has-color' : '') } style={{color: props.textColor}}>
            {index === paths.length - 1 ? (
              <span>{capitalize(path)}</span>
            ) : (
              <Link to={`/${paths.slice(0, index + 1).join('/')}`} style={{color: props.textColor}}>{capitalize(path)}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
