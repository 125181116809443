import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout: React.FC = () => {
  return (
    <>
      <Header />

      <div style={{paddingTop: '5.0625rem'}}>
        <Outlet />
        <Footer />
      </div>
    </>
  );
}

export default Layout;
