import { getHTML } from "../../services/helper";
import "./StepperContent.scss";

interface IProps {
  linkClass?: string;
  content: {
    header?: string;
    description?: string;
    itemsList: {name?: string, iconClass?: string; value: string}[];
  };
}

const StepperContent: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={`title-section ${props.linkClass ?? ''}`}>
      { props.content.header &&
        <h2 className="mb-3" dangerouslySetInnerHTML={getHTML(props.content.header)}></h2>
      }
      { props.content.description &&
        <p className="mb-3" dangerouslySetInnerHTML={getHTML(props.content.description)}></p>
      }
      {
        props.content.itemsList.length > 0 &&
        props.content.itemsList.map((item, index) =>
            <div key={index} className="step-number d-flex mb-4">
                {
                  !item.iconClass &&
                  <span className={'text-white' + (item.name ? ' step-name' : '')}>{item.name ? item.name : (index + 1)}</span>
                }
                {
                  item.iconClass &&
                  <i className={item?.iconClass}></i>
                }
                <p className="my-auto" dangerouslySetInnerHTML={getHTML(item.value)}></p>
            </div>
        )
      }
    </div>
  );
};

export default StepperContent;
