import { Fragment, useEffect, useState } from "react";
import { getHTML } from "../../services/helper";
import "./Carousel.scss";
import { Link } from "react-router-dom";

interface IProps {
    slideDelay?: number,
    linkClass?: string,
    content: {
        list: {
            image: string,
            header?: string,
            description?: string,
            button?: {
                url: string;
                name: string;
            }
            caption?: {
                header?: string,
                description?: string
            }
        }[]
    }
}

const Carousel: React.FC<IProps> = (props: IProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => setTimeout(() => {
            if (currentImageIndex + 1 === props.content.list.length) {
                setCurrentImageIndex(0);
            }  else {
                setCurrentImageIndex( currentImageIndex + 1 );
            }
        }), (props.slideDelay || 1) * 1000);
        return () => {
          clearInterval(interval);
        };
    });

    return (
      <div
        id="carouselIndicators"
        className={"carousel slide " + props.linkClass}
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {props.content.list.length > 0 &&
            props.content.list.map((_item, index) => (
              <li
                key={index}
                data-target="#carouselIndicators"
                data-slide-to={index}
                className={currentImageIndex === index ? "active" : ""}
                onClick={() => setCurrentImageIndex(index)}
              ></li>
            ))}
        </ol>
        <div className="carousel-inner">
          {props.content.list.length > 0 &&
            props.content.list.map((_item, index) => (
              <Fragment key={index}>
                <div
                  className={
                    "carousel-item" +
                    (currentImageIndex === index ? " active" : "")
                  }
                >
                  <img
                    className="d-block w-100 image"
                    src={_item.image}
                    alt={"slide" + index}
                  />
                </div>

                <div
                  className={
                    "carousel-center-caption " +
                    (currentImageIndex === index ? "d-md-block" : "d-none")
                  }
                >
                  <div className="container h-100 d-grid">
                    <div className="row">
                      <div className="col-12">
                        <div className="carousel-center-caption-inner text-white px-3">
                          {_item.header && (
                            <h1
                              dangerouslySetInnerHTML={getHTML(_item.header)}
                            ></h1>
                          )}
                          {_item.description && (
                            <p
                              dangerouslySetInnerHTML={getHTML(
                                _item.description
                              )}
                            ></p>
                          )}
                          {_item.button?.url && (
                            <Link
                              className="d-inline-block text-white my-2 link w-100"
                              to={_item.button.url}
                            >
                              <button
                                className="btn bg-color-primary shadow-md btn-round text-light btn-lg btn-rised w-100"
                                type="button"
                              >
                                <span className="h5">{_item.button.name}</span>
                              </button>
                            </Link>
                          )}
                        </div>
                        {_item.caption && _item.caption.header && (
                          <h5
                            dangerouslySetInnerHTML={getHTML(
                              _item.caption.header
                            )}
                          ></h5>
                        )}
                        {_item.caption && _item.caption.description && (
                          <p
                            dangerouslySetInnerHTML={getHTML(
                              _item.caption.description
                            )}
                          ></p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
      </div>
    );
};

export default Carousel;
