import HeroBlock from "../../components/HeroBlock/HeroBlock";
import Paragraph from "../../components/Paragraph/Paragraph";
import { ContactContent } from "./Contact-Content";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import TwoColumn from "../../components/TwoColumn/TwoColumn";
import CardBlocks from "../../components/CardBlocks/CardBlocks";

const Contact: React.FC = () => {
  return (
    <>
      <HeroBlock
        textAlign="Center"
        style={{minHeight: '30rem'}}
        containerStyle={{maxWidth: '50rem'}}
        content={ContactContent.heroBreadCrumb}
      />
      <section className="container py-4">
        <Paragraph isPageHeader={true} content={ContactContent.paragraph1} />
      </section>
      <div className="container py-5">
        <CardBlocks
          gap={'1.75rem'}
          maxCardsPerRow={4}
          itemClass="text-center"
          content={ContactContent.cardBlocks1}
        />
      </div>
      <TwoColumn
        column1Class="img-overlay"
        column2Class="bg-color-alice-blue p-4"
        leftColumnPercentage={40}
        content={ContactContent.customBlock1}
      />
      <section className="container pt-4 pb-5 my-3">
        <iframe
          title="gmapt-buford"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.7023947948674!2d-84.01882152430139!3d34.102763673138035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f593fdfbec7a0d%3A0xf15ab7134492025e!2s4555%20Atwater%20Ct%2C%20Buford%2C%20GA%2030518!5e0!3m2!1sen!2sus!4v1698119328585!5m2!1sen!2sus"
          width="100%"
          height="450"
          style={{border: 0}}
          loading="eager"
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </section>
      <SectionBanner
        textAlign="Center"
        linkClass="bg-color-alice-blue py-4"
        content={ContactContent.sectionBanner}
      />
    </>
  );
};

export default Contact;