import React from 'react';
import { getHTML } from '../../services/helper';
import CarouselReactive from '../CarouselReactive/CarouselReactive';
import './CarouselReactiveBlock.scss';

interface IProps {
  linkClass?: string;
  designType?: 'full' | 'compact';
  backgroundClass?: string;
  fixedHeight?: number;
  showDots?: boolean;
  content: {
    header?: string;
    description?: string;
    images: string[];
  }
}

const CarouselReactiveBlock: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={`${props.designType === 'full' ? (props.backgroundClass || '') : ''} ${props.linkClass ?? ''}`}>
      <div className={`container mt-5 carousel-image-block ${props.designType === 'full' && props.backgroundClass ? 'py-5' : ''}`}>
        <div className={`${props.designType === 'full' ? '' : `${props.backgroundClass ? props.backgroundClass + ' p-4 rounded' : ''}`}`}>
          <div className="row">
            <div className="col-12 col-md-3 my-auto">
              <h2>{props.content.header}</h2>
              <p dangerouslySetInnerHTML={getHTML(props.content.description || '')}></p>
            </div>
            <div className="col-12 col-md-9">
              <div className={`card px-1 py-3 h-100 justify-content-center ${props.backgroundClass ? 'border-0' : ''}`}>
                <CarouselReactive
                  content={props.content}
                  fixedHeight={props.fixedHeight}
                  showDots={props.showDots}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselReactiveBlock;