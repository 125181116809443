import { useRef } from "react";
import FeatureBlock from "../../components/FeatureBlock/FeatureBlock";
import HeroBlock from "../../components/HeroBlock/HeroBlock";
import Paragraph from "../../components/Paragraph/Paragraph";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import { ServicesContent } from "./Services-Content";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";

const Services: React.FC = () => {
    const ref = {
        featureBlock1: useRef<HTMLDivElement | null>(null),
        featureBlock2: useRef<HTMLDivElement | null>(null),
        featureBlock3: useRef<HTMLDivElement | null>(null),
        featureBlock4: useRef<HTMLDivElement | null>(null),
        featureBlock5: useRef<HTMLDivElement | null>(null),
    }

    const scrollToRef = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
        if (ref && ref.current) {
            const headerOffset = document.querySelector('header')?.offsetHeight || 0;
            const offsetPosition = ref.current.offsetTop - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }

    return (
        <>
            <HeroBlock
                textAlign="Center"
                style={{minHeight: '30rem'}}
                containerStyle={{maxWidth: '50rem'}}
                content={ServicesContent.heroBreadCrumb}
            />
            <SectionBanner
                textAlign="Center"
                linkClass="bg-color-alice-blue py-4"
                content={ServicesContent.sectionBanner1}
            />
            <section className="container py-4">
                <Paragraph
                    isPageHeader={true}
                    content={ServicesContent.paragraph1}
                />
                <ButtonGroup
                    className="pt-4"
                    content={ServicesContent.buttonGroup}
                    onClick={(id) => scrollToRef(ref[id as keyof typeof ref])}
                />
            </section>
            <section ref={ref.featureBlock1} className="pt-5 mt-3">
                <Paragraph
                    className="container text-center mb-3"
                    descriptionClassName="title-span2"
                    content={ServicesContent.featureBlock1Title}
                />
                <FeatureBlock
                    imagePosition="left"
                    backgroundClass='bg-color-alice-blue'
                    content={ServicesContent.featureBlock1}
                />
            </section>
            <section ref={ref.featureBlock2} className="pt-5 mt-3">
                <Paragraph
                    className="container text-center mb-3"
                    descriptionClassName="title-span2"
                    content={ServicesContent.featureBlock2Title}
                />
                <FeatureBlock
                    imagePosition="right"
                    backgroundClass='bg-color-alice-blue'
                    content={ServicesContent.featureBlock2}
                />
            </section>
            <section ref={ref.featureBlock3} className="pt-5 mt-3">
                <Paragraph
                    className="container text-center mb-3"
                    descriptionClassName="title-span2"
                    content={ServicesContent.featureBlock3Title}
                />
                <FeatureBlock
                    imagePosition="left"
                    backgroundClass='bg-color-alice-blue'
                    content={ServicesContent.featureBlock3}
                />
            </section>
            <section ref={ref.featureBlock4} className="pt-5 mt-3">
                <Paragraph
                    className="container text-center mb-3"
                    descriptionClassName="title-span2"
                    content={ServicesContent.featureBlock4Title}
                />
                <FeatureBlock
                    imagePosition="right"
                    backgroundClass='bg-color-alice-blue'
                    content={ServicesContent.featureBlock4}
                />
            </section>
            <section ref={ref.featureBlock5} className="py-5 my-3">
                <Paragraph
                    className="container text-center mb-3"
                    descriptionClassName="title-span2"
                    content={ServicesContent.featureBlock5Title}
                />
                <FeatureBlock
                    imagePosition="left"
                    backgroundClass='bg-color-alice-blue'
                    content={ServicesContent.featureBlock5}
                />
            </section>
            <SectionBanner
                textAlign="Center"
                linkClass="bg-color-alice-blue py-4"
                content={ServicesContent.sectionBanner}
            />
        </>
    );
};

export default Services;
