import HeroImage from "../../assets/images/home/hero/hero-main.jpg";
import FeatureImage1 from "../../assets/images/home/feature/feature-1.jpeg";
import FeatureImage2 from "../../assets/images/home/feature/feature-2.jpeg";
import Android from "../../assets/images/tech/android.png";
import AWS from "../../assets/images/tech/aws.png";
import Azure from "../../assets/images/tech/Azure.png";
import DotNet from "../../assets/images/tech/dotnet.png";
import IOS from "../../assets/images/tech/ios.png";
import Java from "../../assets/images/tech/java.png";
import JIRA from "../../assets/images/tech/jira.png";
import MsSQL from "../../assets/images/tech/ms-sql-server.png";
import Oracle from "../../assets/images/tech/oracle.png";
import RubyRails from "../../assets/images/tech/ruby-rails.png";
import SAP from "../../assets/images/tech/SAP.png";
import Paragraph from "../../components/Paragraph/Paragraph";

export const HomeContent = {
  carousel: {
    list: [
      {
        image: HeroImage,
        header: `Empowering Your IT`,
        description: `Unlock the potential of technology with our expert IT consulting services. Our experienced team of IT consultants is here to guide you through every step of your IT journey, ensuring success and growth.`,
        button: {
          url: '/about-us',
          name: 'Learn More'
        }
      },
      {
        image: HeroImage,
        header: `Transforming Businesses`,
        description: `Discover our global IT services designed to drive measurable outcomes. We combine a distinctive perspective with proven management experience to increase the value of your partnerships and deliver results that matter.`,
        button: {
          url: '/contact',
          name: 'Get in Touch'
        }
      },
    ],
  },
  sectionBanner1: {
    header: "Your Trusted IT Partner",
    description:
      "Experience excellence in IT consulting and services with RAYITS. We're not just a service provider; we're your trusted partner on the path to IT transformation. Our track record of success and commitment to delivering results sets us apart, making us the reliable choice for your IT needs.",
  },
  cardBlocks1: {
    header: 'Explore Our Services',
    description: 'We offer a comprehensive range of IT solutions tailored to meet your specific needs.',
    items: [
        {
            iconClass: 'fas fa-cogs icon-bg-design',
            header: 'IT Consulting',
            description: 'Our IT consulting services provide strategic insights and tailored solutions to enhance your IT capabilities, helping you achieve your business goals with precision and confidence.'
        },
        {
            iconClass: 'fas fa-globe icon-bg-design',
            header: 'Global IT Service',
            description: 'Leverage our extensive experience and global perspective to deliver measurable outcomes for your business. We partner with you to drive success on a global scale.'
        },
        {
            iconClass: 'fas fa-cloud icon-bg-design',
            header: 'Outsourcing Services',
            description: 'Reduce costs while maintaining in-house technical expertise with our outsourcing services. We make outsourcing simple, ensuring your business remains agile and competitive.'
        },
        {
          iconClass: 'fas fa-desktop icon-bg-design',
          header: 'IT Managed Services',
          description: 'From project-based solutions to comprehensive IT management, our services cover your entire IT portfolio. We offer a scalable delivery model to help you achieve your business objectives.'
        },
        {
            iconClass: 'fas fa-graduation-cap icon-bg-design',
            header: 'Career Guidance',
            description: 'Navigate the competitive edge through software development. Our career guidance services ensure your projects are completed on time and within budget, giving you a competitive advantage.'
        },
        {
            iconClass: 'fas fa-lightbulb icon-bg-design',
            header: 'Expert Solutions',
            description: 'Our expert solutions encompass IT consulting and business transformation. We offer carefully considered strategies and innovative solutions to help you reach your goals.'
        },
    ]
  },
  featureBlock1: {
    image: FeatureImage1,
    block: {
      component: Paragraph,
      props: {
        content: {
          header: "Why Choose Us",
          subHeader: "Welcome",
          description: `At RAYITS, we bring a blend of expertise and innovation to every project. We're passionate about delivering exceptional results and ensuring the success of your IT initiatives. Explore what sets us apart.`,
        },
      },
    },
  },
  contentBlock1: {
    header: "Our Unwavering Commitment to Excellence in IT Solutions",
    subHeader: "Our Commitment",
    description:
      "At RAYITS, we are passionately committed to delivering top-notch IT solutions that not only meet but exceed your expectations. Our dedication to quality and excellence is at the core of everything we do. We believe in going the extra mile to ensure your IT initiatives are not just successful but truly exceptional.",
    button: {
      url: '/contact',
      name: 'Get Started'
    }
  },
  featureBlock2: {
    image: FeatureImage2,
    block: {
      component: Paragraph,
      props: {
        content: {
          header: "Meet Our Expert Team",
          subHeader: "Team",
          description: `Our team of highly skilled professionals is dedicated to guiding your IT journey. With years of experience and a passion for innovation, we are here to be your trusted advisors and partners in achieving your IT goals.`,
        },
      },
    },
  },
  carouselTech: {
    header: 'Technologies',
    description: 'Our expertise covers a wide range of technologies.',
    images: [
      Android, AWS, Azure, DotNet, IOS, Java, JIRA, MsSQL, Oracle, RubyRails, SAP
    ]
  },
  sectionBanner2: {
    header: "Ready to Transform Your IT?",
    description: "Embark on your IT journey with RAYITS. Contact us today, and let us help you transform your IT landscape and achieve your business goals. We're here to make your IT dreams a reality.",
    button: {
      url: "/contact",
      name: "Contact Us",
    },
  }
};
