import Image1 from '../../assets/images/why/hero.jpeg';

export const WhyUsContent = {
    heroBreadCrumb: {
        image: Image1,
        header: 'Why Choose Us?',
        description: `Discover the compelling reasons why RAYITS is the perfect choice for your IT needs. We're here to make your journey exceptional.`
    },
    paragraph1: {
        header: "Our Commitment to Excellence",
        description: `
            <p>At RAYITS, we are committed to excellence. Our journey is guided by a dedication to providing top-notch IT solutions and services. We believe in innovation, integrity, and a client-centric approach, ensuring your satisfaction is at the core of everything we do. With a team of dedicated professionals, we are well-equipped to meet your needs, no matter the scale or complexity. Our client relationships are built on trust, transparency, and collaboration, ensuring that your success is our success. Partner with us and experience the difference that sets RAYITS apart.</p>
        `
    },
    stepperContent: {
        header: `<span class="title-bar">Why Us</span>`,
        itemsList: [
          {
            name: "Global IT Expertise",
            value:
              "With a global perspective, we leverage our distinctive insights to deliver measurable outcomes and innovative IT services.",
          },
          {
            name: "Cost-Effective Solutions",
            value:
              "Our cost-effective outsourcing services provide in-house technical expertise, saving you money without compromising quality.",
          },
          {
            name: "Strategic Solutions",
            value:
              "Our strategic IT consulting and business transformation solutions make it easier for you to achieve your specific goals.",
          },
          {
            name: "Quality Assurance",
            value:
              "We pride ourselves on quality assurance, ensuring top-notch services for our in-house projects and clients.",
          },
          {
            name: "Diverse Industry Expertise",
            value:
              "Serving clients across various industries, from government organizations to small businesses, we offer robust skill sets and managerial strength.",
          },
          {
            name: "Comprehensive Services",
            value:
              "Our comprehensive range of services covers application development, infrastructure, education, strategy planning, and more.",
          },
        ],
    },
    sectionBanner: {
        description:
          "Have any question, contact us.",
        button: {
          url: "/contact",
          name: "Contact US",
        },
    }
}
