export const renderComponentJson = (content: any) => {
    if (typeof content === "string") {
      return content;
    }
    if (Array.isArray(content)) {
      return content.map((componentObj, index) => {
        const { component: Component, props } = componentObj;
        return <Component key={index} {...props} />;
      });
    } else if (typeof content === 'object') {
      const { component: Component, props } = content;
      return <Component {...props} />;
    }
    return null;
};
