import HeroBlock from "../../components/HeroBlock/HeroBlock";
import Paragraph from "../../components/Paragraph/Paragraph";
import SectionBanner from "../../components/SectionBanner/SectionBanner";
import StepperContent from "../../components/StepperContent/StepperContent";
import { WhyUsContent } from "./WhyUs-Content";

const WhyUs: React.FC = () => {
  return (
    <>
      <HeroBlock
        textAlign="Center"
        style={{minHeight: '30rem'}}
        containerStyle={{maxWidth: '50rem'}}
        content={WhyUsContent.heroBreadCrumb}
      />
      <section className="container pt-5 pb-4">
        <Paragraph isPageHeader={true} content={WhyUsContent.paragraph1} />
      </section>
      <section className="container py-4">
        <StepperContent content={WhyUsContent.stepperContent} />
      </section>
      <SectionBanner
        textAlign="Center"
        linkClass="bg-color-alice-blue py-4"
        content={WhyUsContent.sectionBanner}
      />
    </>
  );
};

export default WhyUs;
