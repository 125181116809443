import { Link } from "react-router-dom";
import { getHTML } from "../../services/helper";

interface IProps {
  className?: string;
  headerClassName?: string;
  descriptionClassName?: string;
  isPageHeader?: boolean;
  content: {
    header?: string;
    subHeader?: string;
    description?: string;
    button?: {
      url: string;
      name: string;
    }
  };
}

const Paragraph: React.FC<IProps> = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.content.header &&
        (props.isPageHeader ? (
          <>
            { props.content.subHeader && <span className="title-span2">{props.content.subHeader}</span> }
            <h1 className={props.headerClassName}>{props.content.header}</h1>
          </>
        ) : (
          <>
            { props.content.subHeader && <span className="title-span2">{props.content.subHeader}</span> }
            <h2 className={props.headerClassName}>{props.content.header}</h2>
          </>
        ))}
      {props.content.description && (
        <p
          className={`${props.isPageHeader ? 'pt-0' : ''} ${props.descriptionClassName ?? ''}`}
          dangerouslySetInnerHTML={getHTML(props.content.description)}
        ></p>
      )}
      {props.content.button && (
        <Link
          className="d-inline-block text-white my-2 link w-100"
          to={props.content.button.url}
        >
          <button
            className="btn bg-color-primary shadow-md text-light w-100"
            type="button"
            style={{maxWidth: '200px'}}
          >
            {props.content.button.name}
          </button>
        </Link>
      )}
    </div>
  );
};

export default Paragraph;
