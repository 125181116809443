import React from 'react';
import { renderComponentJson } from '../../services/render-component-json';
import './TwoColumn.scss';

interface IProps {
  linkClass?: string;
  column1Class?: string;
  column2Class?: string;
  leftColumnPercentage: number;
  content: {
    chipText?: string;
    column1: any;
    column2: any;
  }
}

const TwoColumn: React.FC<IProps> = (props: IProps) => {
  const rightColumnPercentage = 100 - props.leftColumnPercentage;
  return (
    <div className={`container py-4 ${props.linkClass ?? ''}`}>
      { props.content.chipText && (
        <div className="row">
          <div className="col-12 pb-3">
            <span className="title-span3">{props.content.chipText}</span>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div
            className="d-grid grid-col-2"
            style={{
              '--left': `${props.leftColumnPercentage}%`,
              '--right': `${rightColumnPercentage}%`
            } as React.CSSProperties }>
            <div className={`${props.column1Class ?? ''}`}>{ renderComponentJson(props.content.column1) }</div>
            <div className={`${props.column2Class ?? ''}`}>{ renderComponentJson(props.content.column2) }</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumn;