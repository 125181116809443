import HeroImage from '../../assets/images/about/hero.jpeg';
import FeatureImage1 from "../../assets/images/about/feature-1.jpeg";
import FeatureImage2 from "../../assets/images/about/feature-2.jpeg";
import CardBlocks from '../../components/CardBlocks/CardBlocks';
import Paragraph from '../../components/Paragraph/Paragraph';
import StepperContent from '../../components/StepperContent/StepperContent';

export const AboutContent = {
    heroBreadCrumb: {
        image: HeroImage,
        header: 'About RAYITS',
        description: 'We are more than just an IT consulting firm. We are your strategic partner on a journey of technological innovation and transformation. Our passion for excellence and commitment to our clients drive us forward. Discover how we unleash the power of IT innovation to shape a better future.'
    },
    sectionBanner1: {
        header: "Our Values and Commitment",
        description:
          "At RAYITS, we uphold a set of core values that define our path and our unswerving commitment to excellence. These values are the cornerstone of our journey, ensuring your success and ours.",
    },
    cardBlocks1: {
        items: [
            {
                iconClass: 'fas fa-bullseye',
                header: 'Dedication',
                description: `Dedication is our cornerstone. We prioritize your needs and consistently exceed your expectations with unwavering dedication.` 
            },
            {
                iconClass: 'fas fa-lightbulb',
                header: 'Innovation',
                description: `Innovation is our driving force. We continuously seak fresh ideas and emerging technologies to drive progress and stay ahead of the curve.`
            },
            {
                iconClass: 'fas fa-users',
                header: 'Collaboration and Partnership',
                description: 'Collaboration and partnership are our guiding principles. We work together with clients, building relationships, and achieving success.'
            },
        ]
    },
    featureBlock1: {
        image: FeatureImage1,
        block: [
            {
                component: Paragraph,
                props: {
                    content: {
                        header: "Our Guiding Principles",
                    },
                },
            },
            {
                component: StepperContent,
                props: {
                    content: {
                        header: ``,
                        itemsList: [
                            {
                                iconClass: "fas fa-balance-scale fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "Ethical values are the cornerstone of our identity. We hold ourselves to the highest ethical standards, ensuring integrity in every action.",
                            },
                            {
                                iconClass: "fas fa-globe fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "We embrace a global perspective, delivering solutions that transcend borders and adapt to the dynamic IT landscape worldwide.",
                            },
                            {
                                iconClass: "fas fa-users-cog fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "Our people-centered approach places clients and team members at the forefront. We prioritize relationships and individual well-being.",
                            },
                        ]
                    }
                }
            }
        ]
    },
    featureBlock2: {
        image: FeatureImage2,
        block: [
            {
                component: Paragraph,
                props: {
                    content: {
                        header: "Our Expertise",
                    },
                },
            },
            {
                component: StepperContent,
                props: {
                    content: {
                        header: ``,
                        itemsList: [
                            {
                                iconClass: "fas fa-code fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "Software Development - We excel in developing cutting-edge software solutions.",
                            },
                            {
                                iconClass: "fas fa-server fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "Infrastructure Management - Our expertise ensures robust IT infrastructure.",
                            },
                            {
                                iconClass: "fas fa-graduation-cap fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "Training & Education - We provide industry-focused training to shape IT careers.",
                            },
                            {
                                iconClass: "fas fa-cogs fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "IT Strategy & Planning - Our strategies drive IT success in your business.",
                            },
                            {
                                iconClass: "fas fa-tasks fa-2x icon-bg-circle bg-color-primary text-white mr-4",
                                value:
                                    "Project Management - We manage projects efficiently, meeting deadlines and objectives.",
                            },
                        ]
                    }
                }
            }
        ]
    },
    customBlock1: {
        chipText: 'Guiding Your Journey',
        column1: {
            component: Paragraph,
            props: {
                content: {
                    header: "Our Philosophy",
                },
            },
        },
        column2: {
            component: CardBlocks,
            props: {
                maxCardsPerRow: 2,
                gap: '2rem',
                content: {
                    items: [
                        {
                            iconClass: '',
                            header: 'Client-Centric Solutions',
                            description: `Our philosophy is rooted in a client-centric approach. We prioritize your needs, build strong relationships, and ensure your success is our success.`,
                        },
                        {
                            iconClass: '',
                            header: 'Collaborative Excellence',
                            description: 'Collaboration is a core element of our philosophy. We work closely with clients, fostering partnerships and achieving excellence together.',
                        }
                    ]
                }
            }
        }
    }
}