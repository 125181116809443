interface IProps {
  linkClass?: string;
  content: {
    firstNameLabel?: string;
    lastNameLabel?: string;
    emailLabel?: string;
    subjectLabel?: string;
    messageLabel?: string;
    buttonLabel?: string;
  };
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => any;
}

const ContactForm: React.FC<IProps> = (props: IProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.onSubmit) {
      props.onSubmit(event);
    } else {
      const form = event.currentTarget;
      const first_name = form.elements.namedItem('c_fname') as HTMLInputElement;
      const last_name = form.elements.namedItem('c_lname') as HTMLInputElement;
      const email = form.elements.namedItem('c_email') as HTMLInputElement;
      const subject = form.elements.namedItem('c_subject') as HTMLTextAreaElement;
      const message = form.elements.namedItem('c_message') as HTMLTextAreaElement;
  
      const mailtoLink = `mailto:info@rayits.com?subject=${encodeURIComponent(
        subject.value || 'Contact Form Submission'
      )}&body=${encodeURIComponent(
        `Name: ${first_name.value + ' ' + last_name.value}\nEmail: ${email.value}\nMessage: ${message.value}`
      )}`;
  
      window.location.href = mailtoLink;
    }
  }
  return (
    <form onSubmit={handleSubmit} className={props.linkClass}>
      <div className="form-group row">
        {props.content.firstNameLabel && (
          <div className="col-md-6">
            <label htmlFor="c_fname" className="text-black">
              {props.content.firstNameLabel}{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="c_fname"
              name="c_fname"
              required
            />
          </div>
        )}

        {props.content.lastNameLabel && (
          <div className="col-md-6">
            <label htmlFor="c_lname" className="text-black">
              {props.content.lastNameLabel}{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="c_lname"
              name="c_lname"
              required
            />
          </div>
        )}
      </div>

      {props.content.emailLabel && (
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="c_email" className="text-black">
              {props.content.emailLabel}{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="c_email"
              name="c_email"
              placeholder=""
              required
            />
          </div>
        </div>
      )}

      {props.content.subjectLabel && (
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="c_subject" className="text-black">
              {props.content.subjectLabel}{" "}
            </label>
            <input
              type="text"
              className="form-control"
              id="c_subject"
              name="c_subject"
            />
          </div>
        </div>
      )}

      {props.content.messageLabel && (
        <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="c_message" className="text-black">
              {props.content.messageLabel}{" "}
            </label>
            <textarea
              name="c_message"
              id="c_message"
              cols={30}
              rows={7}
              className="form-control"
              required
            ></textarea>
          </div>
        </div>
      )}
      
      {props.content.buttonLabel && (
        <div className="form-group row">
          <div className="col-lg-12">
            <input
              type="submit"
              className="btn bg-color-primary text-white btn-lg btn-block"
              value={props.content.buttonLabel}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default ContactForm;
