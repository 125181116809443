import Image1 from '../../assets/images/contact/hero.jpeg';
import ContactForm from '../../components/ContactForm/ContactForm';
import ImageResponsive from '../../components/ImageResponsive/ImageResponsive';
import DesktopImage from '../../assets/images/contact/form/desktop.jpeg';
import MobileImage from '../../assets/images/contact/form/mobile.jpeg';

export const ContactContent = {
    heroBreadCrumb: {
        image: Image1,
        header: 'Contact Us',
        description: `Get in touch with RAYITS. We're here to answer your questions and assist you with your IT needs.`
    },
    paragraph1: {
        header: "Reach Out to Us",
        description: "We are always here to assist you. If you have any inquiries, require support, or want to explore our IT services, don't hesitate to contact us. Our dedicated team is ready to provide solutions tailored to your needs."
    },
    cardBlocks1: {
        items: [
            {
                iconClass: 'fa fa-map-marker icon-bg-circle bg-color-primary text-white',
                description: `
                <p>
                    <strong>Address</strong>
                    <br/>
                    <a href="https://maps.google.com/maps/dir//4555+Atwater+Ct+Buford,+GA+30518/@34.1027637,-84.0162466,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x88f593fdfbec7a0d:0xf15ab7134492025e" target="_blank">4555 Atwater Ct, Suite 202, Buford, GA 30518</a>
                </p>` 
            },
            {
                iconClass: 'fa fa-phone icon-bg-circle bg-color-primary text-white',
                description: `
                <p>
                    <strong>Phone</strong>
                    <br/>
                    <a href="tel://14706657556">+ 1 470 665 7556</a>
                </p>`
            },
            {
                iconClass: 'fa fa-paper-plane icon-bg-circle bg-color-primary text-white',
                description: `
                <p>
                    <strong>Email</strong>
                    <br/>
                    <a href="mailto:info@rayits.com">info@rayits.com</a>
                </p>`
            },
            {
                iconClass: 'fas fa-clock icon-bg-circle bg-color-primary text-white',
                description: `
                <p>
                    <strong>Working Hours</strong>
                    <br/>
                    Mon - Fri: 9 AM - 6 PM EST
                </p>`
            },
        ]
    },
    customBlock1: {
        column1: {
            component: ImageResponsive,
            props: {
                linkClass: "img-fluid",
                desktop: {
                    image: DesktopImage,
                    className: 'h-100'
                },
                tablet: {
                    image: MobileImage,
                    className: ''
                },
                mobile: {
                    image: MobileImage,
                    className: ''
                },
            },
        },
        column2: {
            component: ContactForm,
            props: {
                content: {
                    firstNameLabel: 'First Name',
                    lastNameLabel: 'Last Name',
                    emailLabel: 'Email',
                    subjectLabel: 'Subject',
                    messageLabel: 'Message',
                    buttonLabel: 'Send Message'
                }
            }
        }
    },
    contactForm: {
        title: '',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        emailLabel: 'Email',
        subjectLabel: 'Subject',
        messageLabel: 'Message',
        buttonLabel: 'Send Message'
    },
    sectionBanner: {
        header: "Discover What We Offer",
        description:
          "Explore the range of IT services and solutions provided by RAYITS. We're here to empower your IT journey and drive success.",
        button: {
          url: "/services",
          name: "Explore Our Services",
        },
    }
}